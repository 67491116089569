import './index.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Payments from './payments';
import { ReactComponent as DeleteIcon } from './delete.svg';
import MainTokenExeption from './MainTokenExeption';
import Header from './header';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Footer from './Footer';

function Curse() {
    const [token, setToken] = useState('');

    useEffect(() => {
        setToken(
            localStorage.getItem('token')
        );
    }, []);

    function tokenExpired() {
        localStorage.setItem('token', '');
        setToken('');
    }

    return (

        <div>
            {
                token == '' && (
                    <MainTokenExeption />
                )
            }
            {
                token !== '' && (
                    <CurseDetails tokenExpired={tokenExpired} />
                )

            }

        </div>

    );
}

function CurseDetails({ tokenExpired }) {
    var userName = localStorage.getItem('userName');
    const token = localStorage.getItem('token');
    const { courseUID } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [student, setStudent] = useState([]);
    const [error, setError] = useState(
        {
            isOpen: false,
            errorText: ''
        });
    const [paymentsData, setPaymentData] = useState(
        {
            isOpen: false,
            data: []
        });
    const [name, setName] = useState('');



    function showPaymentsModal(payments) {
        console.log(payments);

        setPaymentData(
            {
                isOpen: true,
                data: payments
            }
        );
    }

    function hidePaymentsModal() {
        setPaymentData(
            {
                isOpen: false,
                data: []
            }
        );
    }


    function handleGoBack() {
        navigate('/main'); // Переход на страницу "main"
    }

    function handleClick(event) {
        navigate('/');
    }

    useEffect(() => {
        getRates();
    }, []);

    function getDateTime(dateString) {
        const formattedDate = new Date(dateString).toLocaleString();
        return formattedDate
    }

    function getLocalizetBoolean(bool) {
        if (bool == true) {
            return "Да"
        } else {
            return "Нет"
        }
    }

    function studentStatus(isEnrolled, isCancelled) {
        if (isEnrolled == true && isCancelled == true) {
            return "Зачислен"
        }

        if (isEnrolled == false && isCancelled == true) {
            return "Отменен"
        }

        if (isEnrolled == false && isCancelled == true) {
            return "isEnrolled = false, isCancelled = true"
        }

        if (isEnrolled == true && isCancelled == false) {
            return "isEnrolled = true, isCancelled = false"
        }
    }

    async function getRates() {
        console.log(courseUID);

        try {
            const response = await fetch(`https://fp-services.ru/installment/courses/get?course_uid=${courseUID}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.ok) {
                const responseData = await response.json();

                console.log(responseData.Data);

                setData(responseData.Data);

                setStudent(responseData.Data.Students)

            } else if (response.status === 401) {
                // Токен истек или недействителен
                console.log('Токен истек или недействителен');

                console.log("getRates tokenExpired")

                tokenExpired();
            } else {
                const errorData = await response.json();
                console.error('CurseDetails error', errorData);
                setError({
                    isOpen: true,
                    errorText: errorData,
                });
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }



    };

    async function deleteCource(courseUID) {
        try {
            const response = await fetch(`https://fp-services.ru/installment/courses/delete?course_uid=${courseUID}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`
                }, // Отправляем данные нового курса
            });

            if (response.ok) {
                const data = await response.json();

                console.log(data);

                getRates();


            } else if (response.status == 401) {
                // Токен истек или недействителен
                console.log('Токен истек или недействителен');

                alert('токен истек')
            } else {
                const errorData = await response.json();
                console.error('Ошибка создания курса:', errorData);
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }
    }

    function handleDeleteClick(courseUID) {
        if (window.confirm("Вы точно хотите удалить курс?") == true) {
            deleteCource(courseUID);
            navigate('/main');
        }
    }
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <div className='main'>
            <Payments paymentsData={paymentsData} cancel={hidePaymentsModal} />

            {/* Шапка сайта */}
            <Header />

            <div className='button-inner'>
                <button className='button-back' onClick={handleGoBack}>Назад</button>
                <button className='button-dele' onClick={() => handleDeleteClick(courseUID)}>Удалить</button>
            </div>

            <div className='detail-content'>
                <div className='spans-title'>
                    <div className='spans'>
                        <b>Название</b>
                        <p>{data.Name}</p>
                    </div>

                    <div className='spans'>
                        <b>Цена</b>
                        <p>{data.Price} ₽</p>
                    </div>

                    <div className='spans'>
                        <b>Длительность</b>
                        <p>{data.Duration}</p>
                    </div>
                    <div className='spans-2'>
                        <b>Ссылка на рассрочку по курсу</b>
                        <p><a href={data.InstallmentLink}>{data.InstallmentLink}</a></p>
                    </div>
                </div>

                <div className='spans-title'>


                </div>

                <table className='table'>
                    <thead className='table_thead'>
                        <tr>
                            <th className='table_th'>Имя студента</th>
                            <th className='table_th'>Телефон</th>
                            <th className='table_th'>Дата начала</th>
                            <th className='table_th'>Полученная сумма</th>
                            <th className='table_th'>Статус</th>
                            <th className='table_th'>Платежи</th>
                        </tr>
                    </thead>

                    {student !== null && (
                        <tbody>
                            {
                                student.map((сource, index) => (
                                    <tr key={index}>
                                        <td className='table_td'>{сource.Name}</td>
                                        <td className='table_td'>{сource.PhoneNumber}</td>
                                        <td className='table_td'>{getDateTime(сource.DateStart)}</td>
                                        <td className='table_td'>{сource.SumNeed} ₽</td>
                                        <td className='table_td'>{studentStatus(сource.IsEnrolled, сource.IsCancelled)}</td>
                                        <td className='table_td'>
                                            {сource.Payments && сource.Payments.length > 0 ? (
                                                <>
                                                    {сource.Payments.length} плат.
                                                    <button className='button-look' type="button" onClick={() => showPaymentsModal(сource.Payments)}>Посмотреть</button>
                                                </>
                                            ) : (
                                                'Нет платежей'
                                            )}
                                        </td>
                                    </tr>

                                ))
                            }
                        </tbody>
                    )}

                </table>
            </div>


            <Footer />
        </div>
    );





}


export default Curse;




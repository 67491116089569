import React from 'react';
import { Document, Page } from 'react-pdf';

function PdfViewer () {
  return (
    <div>
   
    </div>
  );
};

export default PdfViewer;


import './index.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ReactComponent as Cancel } from './cancel.svg';
import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';




function Create({ opening, cancel, success, tokenExpired }) {
  const token = localStorage.getItem('token');
  const [error, setError] = useState(false);
  const [age, setAge] = React.useState('');
  const [newCource, setNewCource] = useState({
    Name: '',
    ShortID: uniqueId(),
    Price: '',
    ShopTag: '',
    SourceLink: "",
    Description: '',
    detailsButton: true, // флаг указывает на наличие кнопки "Подробнее"
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setNewCource((prevCourse) => ({
      ...prevCourse,
      [name]: value
    }));
  }


  async function createCource() {
    try {
      const response = await fetch("https://fp-services.ru/installment/courses/create", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(newCource) // Отправляем данные нового курса
      });

      if (response.ok) {
        const data = await response.json();

        console.log(data);
        // Обновляем список курсов после успешного создания
        success()
      } else if (response.status === 401) {
        // Токен истек или недействителен
        console.log('Токен истек или недействителен');

        tokenExpired();
      } else {
        const errorData = await response.json();
        console.error('Ошибка создания курса:', errorData);
      }

      resetInputs()
    } catch (error) {
      console.error('Ошибка:', error);
      resetInputs()
    }
  }

  function resetInputs() {
    setNewCource({
      Name: '',
      ShortID: uniqueId(),
      Price: '',
      ShopTag: '',
      SourceLink: '',
      Description: '',
      detailsButton: true,
    });
  }

  function uniqueId() {
    const letters = 'abcdefghijklmnopqrstuvwxyz'; // набор букв, из которого будет производиться выбор
    let id = '';
    let length = 5;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      const randomLetter = letters.charAt(randomIndex);
      id += randomLetter;
    }

    return id;
  }

  function handleSaveClick() {
    if (
      newCource.Name === '' ||
      newCource.Price === '' ||
      newCource.ShopTag == ''

    ) {
      console.log('error:', error);
      console.log('newCource.ShopTag:', newCource.ShopTag);
      setError(true);
    } else {
      createCource();
    }
    

  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    console.log(typeof name, typeof value);
    setNewCource((prevCource) => ({
      ...prevCource,
      [name]: value
    }));
  }

  function handlePriceInputChange(event) {
    const { name, value } = event.target;

    let formattedValue = value.replace(/[^0-9]/g, '')

    if (formattedValue.length > 0 && formattedValue.charAt(0) === '0') {
      formattedValue = formattedValue.slice(1);
    }

    if (formattedValue.length > 0 && formattedValue != '') {
      formattedValue = parseFloat(formattedValue)
    }



    setNewCource((prevCource) => ({
      ...prevCource,
      [name]: formattedValue
    }));


    // Вызвать функцию cancel из пропсов
  }

  function handleCancelClick() {
    setNewCource({
      Name: '',
      ShortID: uniqueId(),
      Price: '',
      ShopTag: '',
      SourceLink: '',
      Description: '',
      detailsButton: true,
    });

    resetErrors();
    cancel();

  }

  function resetErrors() {
    setError(false);
  }


  return (
    <div>

      {opening && (
        <div className="modal">
          <button className='modal-cancellation' onClick={handleCancelClick}>

          </button>
          <div className="create">
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { marginTop: '16px', width: '308px' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                value={newCource.Name}
                onChange={handleInputChange}
                name="Name"
                type="text"
                className='create-description'
                id="outlined-basic"
                label="Название"
                variant="outlined"
                error={error && newCource.Name === ''}
                helperText={error && newCource.Name === '' ? 'Заполните поле Название' : ''}
              />
              <TextField
                value={newCource.Price}
                name="Price"
                type="text"
                onChange={handlePriceInputChange}
                className='create-description'
                id="outlined-basic"
                label="Цена"
                variant="outlined"
                InputProps={{
                  endAdornment:
                    <InputAdornment
                      position="end">₽</InputAdornment>
                }}
                error={(error && newCource.Price === '') || newCource.Price === 0 || /^(0[1-9]\d*)$/.test(newCource.Price)}
                helperText={(error && newCource.Price === '') ? 'Заполните поле Название' : (newCource.Price === 0 || /^(0[1-9]\d*)$/.test(newCource.Price) ? 'Цена не может начинаться с 0' : '')}
              />
              {/* <TextField
                value={newCource.ShopTag}
                name="ShopTag"
                type="text"
                onChange={handleInputChange}
                className='create-description'
                id="outlined-basic"
                label="Продолжительность"
                variant="outlined"
                error={error && newCource.ShopTag === ''}
                helperText={error && newCource.ShopTag === '' ? 'Заполните поле Название' : ''}
              /> */}

              <FormControl
                fullWidth
                value={newCource.ShopTag}
                name="ShopTag"
                error={error && newCource.ShopTag == ''}
                helperText={error && newCource.ShopTag == '' ? 'Заполните поле Название' : ''}
              >
                <InputLabel id="demo-simple-select-label">Продолжительность</InputLabel>
                <Select
                  name="ShopTag"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newCource.ShopTag}
                  label="Продолжительность"

                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#299E46', // Change the border color on hover
                    },
                  }}


                >
                  <MenuItem value="3 месяца">3 месяца</MenuItem>
                  <MenuItem value="4 месяца">4 месяца</MenuItem>
                  <MenuItem value="6 месяцев">6 месяца</MenuItem>
                  <MenuItem value="12 месяцев">12 месяцев</MenuItem>
                </Select>
              </FormControl>

              <TextField
                value={newCource.SourceLink}
                name="SourceLink"
                type="text"
                autocomplete="off"
                onChange={handleInputChange}
                className='create-description'
                id="outlined-basic"
                label="Cсылка"

              />
              <TextField
                name="Description"
                type="text"
                autocomplete="off"
                onChange={handleInputChange}
                value={newCource.Description}
                className='create-description'
                label="Описание"
                multiline
                maxRows={4}
              />
            </Box>
            <div className='create-button'>
              <button onClick={handleSaveClick}>Создать</button>
            </div>
          </div>
          <div class="overlay"> </div>
        </div>
      )}
    </div>
  );
}

export default Create;
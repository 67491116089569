import './index.css';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();
    var userName = localStorage.getItem('userName');

    function handleClick(event) {
        localStorage.clear();
        navigate('/');
        console.log('Токен после удаления:', localStorage.getItem('token'));
    }

    return (
        <div className='main'>
            <div className='header'>
                <div className='header-title'>
                    <div className='logo'></div>
                    <h3 className='header-text'>SHOOKRU EDUCATION</h3>
                </div>

                <div className='header-inner'>
                    <h5 className='Name'>{userName}</h5>
                    <button className='header-button' type="button" onClick={handleClick}>Выйти</button>
                </div>
            </div>
        </div>
    );
}

export default Header;
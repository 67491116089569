import './index.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';




function MainTokenExeption() {
    const navigate = useNavigate();
    var userName = localStorage.getItem('userName');


    function goToLogin(event) {
        navigate('/');
    }

    function handleClick(event) {
        localStorage.clear();
        navigate('/');
        console.log('Токен после удаления:', localStorage.getItem('token'));
    }

    return (
        <div className='main'>
            <div className='error-token'>
                <h1 className='error-token-title'>Сeссия истекла</h1>
                <p className='error-token-inner'>Требуется войти заново</p>
                <button
                    className="button"
                    type="button"
                    onClick={goToLogin}
                >Войти</button>

            </div>

        </div>
    );
}

export default MainTokenExeption;
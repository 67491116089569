import React from 'react';
import PdfViewer from './PdfViewer';

function Footer (Footer){
  const pdfUrl = '/treaty.pdf'; // Замени путь на путь к своему PDF-файлу

  return (
    <div className='footer'>
      {/* Ссылка для открытия PDF в отдельной странице */}
      <a className='treaty' href={pdfUrl} target="_blank" rel="noopener noreferrer">
      Клиентский договор
      </a>

      {/* Отображение PDF с помощью PdfViewer */}
      <PdfViewer pdfUrl={pdfUrl} />
    </div>
  );
};

export default Footer;

import './index.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import Create from './create';
import MainTokenExeption from './MainTokenExeption';
import Header from './header';
import Footer from './Footer';

function Main() {
    const [token, setToken] = useState('');

    useEffect(() => {
        setToken(
            localStorage.getItem('token')
        );
    }, []);

    function tokenExpired() {
        localStorage.setItem('token', '');
        setToken('');
    }


    return (
        <div>
            <Header />

            <div>
                {
                    token == '' && (
                        <MainTokenExeption />
                    )
                }
                {
                    token !== '' && (
                        <MainPage tokenExpired={tokenExpired} />
                    )

                }

            </div>
        </div>

    );
}


function MainPage({ tokenExpired }) {
    var userName = localStorage.getItem('userName');
    const token = localStorage.getItem('token');
    const [rates, setRates] = useState([]);
    const [openingCreateModal, setStateForCreateModal] = useState(false); // Состояние модального окна

    const [error, setError] = useState(
        {
            isOpen: false,
            errorText: ''
        });


    const navigate = useNavigate();

    function handleClick(event) {
        localStorage.clear();
        navigate('/');
        console.log('Токен после удаления:', localStorage.getItem('token'));
    }


    useEffect(() => {
        getRates();
    }, []);


    const [isCreating, setIsCreating] = useState(false); // Флаг для отслеживания создания курса


    function handleClick(event) {
        navigate('/');
    }

    function handleClickCurseDetails(courseUID) {
        navigate(`/сurseDetails/${courseUID}`);
    }

    //Получаем список курсво
    async function getRates() {
        try {
            const response = await fetch("https://fp-services.ru/installment/courses/get-list", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();

                console.log(data);

                setRates(data.Data);

            } else if (response.status == 401) {
                // Токен истек или недействителен
                console.log('Токен истек или недействителен');
                // localStorage.clear();

                // alert('токен истек');
                console.log("getRates tokenExpired")

                tokenExpired();
            } else {
                const errorData = await response.json();
                console.error('Ошибка авторизации:', errorData);

                setError(
                    {
                        isOpen: true,
                        errorText: errorData
                    }

                )

            }
        } catch (error) {
            console.error('Ошибка:', error);
        }

    }
    function createModalResulTokenExpired() {
        tokenExpired();
    }

    function showCreateModal() {
        setStateForCreateModal(true)
    }

    function hideCreateModal() {
        setStateForCreateModal(false)
    }

    function createModalResultSucces() {
        hideCreateModal();
        getRates();

    }


    function handleCreateClick() {

        window.location.reload();
    }

    //   function handleDeleteClick() {
    //     window.location.reload();

    // }

    //Обновление страницы при нажити на кнопку "Повторить в модалке"
    const handleClose = () => {
        window.location.reload();
    };

    //Создаем список курсов
    return (
        <div className='main'>
            <Create
                opening={openingCreateModal}
                cancel={hideCreateModal}
                success={createModalResultSucces}
                tokenExpired={createModalResulTokenExpired
                } />

            <div className="button-table">
                <h1 className='button-table-text'>Мои курсы</h1>
                {!isCreating && (
                    <button type="button" onClick={showCreateModal}>Создать</button>
                )}
            </div>
            <div>
                {
                    rates !== null && rates !== undefined && rates.map((сource, index) => (
                        <div className='course' key={index} onClick={() => handleClickCurseDetails(сource.CourseUID)}>
                            <div className='course-inner'>
                                <div className='course-title'>{сource.Name}</div>
                                <div className='course-title'>{сource.ShopTag}</div>
                                <div className='course-title'>{сource.Price} ₽</div>
                            </div>
                        </div>
                    ))}
            </div>


            <div>
                <Modal isOpen={error.isOpen} onRequestClose={handleClose} className="modal">
                    <h3 className='modal-error'> Ошибка</h3>
                    <p className='modal-text'>{error.errorText}</p>
                    <button className='modal-button' onClick={handleClose}>Повторить</button>
                </Modal>
            </div>



<Footer />

        </div>
    );

}


export default Main;





